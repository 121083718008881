import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import {
  getTableBodyPlaceholder,
  ordersByCustomerBodyPlaceholder
} from '../../helpers/placeholderHelpers';
import Order from '../../propTypes/Order';
import OxCard from '../molecules/OxCard';
import OxButton from '../molecules/OxButton';
import GET_PACKING_SLIPS from '../../api/packingSlips/query';
import '../../styles/components/_list-of-orders.scss';
import { GET_PRODUCT_DOCUMENTS } from '../../api/documents/query';
import GET_COMMERCIAL_INVOICES from '../../api/commercialInvoices/query';
import GET_ACCOUNTING_INVOICES from '../../api/accountingInvoices/query';
import {
  isInternal,
  isGraphqlArrayEmptyForNested,
  materialCodeGetsReachLetter,
  showCommercialInvoices
} from '../../helpers';
import { GET_USER_INFO } from '../../api/user/query';
import GET_MATERIAL_CODES_BY_ORDER_NUMBER from '../../api/materialCodes/query';
import GET_ORDER_ITEMS_BY_ORDER_NUMBER from '../../api/orderItems/query';
import {
  FOOD_SAFE_SERIES,
  REGRIND_MATERIAL_CODES
} from '../../helpers/constants';
import OrderShipments from './OrderShipments';

/**
 * Returns an list of cards for each order.
 * Each order has a list of shipments.
 */
const ListOfOrders = ({ loading, data }) => {
  ListOfOrders.propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.shape({
      orders: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({ ...Order }))
      }),
      accountsByAccountID: PropTypes.shape({
        name: PropTypes.shape({
          value: PropTypes.string
        }),
        accountId: PropTypes.string
      })
    })
  };

  const { data: userData } = useQuery(GET_USER_INFO);

  const [orderNumberToPsCountMap, setOrderNumberToPsCountMap] = useState(
    new Map()
  );

  const [
    orderNumberToCommercialInvoiceMap,
    setOrderNumberToCommercialInvoiceMap
  ] = useState(new Map());

  const [
    orderNumberToAccountingInvoiceCountMap,
    setOrderNumberToAccountingInvoiceCountMap
  ] = useState(new Map());

  const [
    orderNumberHasComplianceLetterMap,
    setOrderNumberHasComplianceLetterMap
  ] = useState(new Map());

  const _getOrders = () => {
    if (loading) {
      return getTableBodyPlaceholder(1, ordersByCustomerBodyPlaceholder);
    }
    return (data.orders && data.orders.data) || [];
  };

  const _getOrderNumber = orderParam =>
    orderParam && orderParam.orderNumber && orderParam.orderNumber.value
      ? orderParam.orderNumber.value
      : '';

  const _getDocumentPageRoute = order => {
    return `/my-orders/details/documents/${_getOrderNumber(order)}/${
      order.accountId
    }`;
  };

  const getPackingSlipValidatorPayload = () => {
    if (data && data.orders && data.orders.data) {
      return data.orders.data.map(order => ({
        orderNumber: order.orderNumber.value
      }));
    }
    return [];
  };

  const getCommercialInvoicePayload = () => {
    if (data && data.orders && data.orders.data) {
      return data.orders.data.map(order => ({
        orderNumber: order.orderNumber.value
      }));
    }
    return [];
  };

  const getAccountingInvoicePayload = () => {
    if (data && data.orders && data.orders.data) {
      return data.orders.data.map(order => ({
        orderNumber: order.orderNumber.value
      }));
    }
    return [];
  };

  // Returns an array of the current order numbers on the page
  const _getMaterialCodeQueryParams = () => {
    return data && data.orders && data.orders.data
      ? data.orders.data.map(order => {
          return {
            orderNumber: order.orderNumber.value,
            orgId: order.orgId
          };
        })
      : [];
  };

  /**
   * Fetch material codes for each order on the page
   * Upon completion, determine which orders should get a compliance letter
   */
  const { loading: materialCodesLoading } = useQuery(
    GET_MATERIAL_CODES_BY_ORDER_NUMBER,
    {
      variables: {
        orders: _getMaterialCodeQueryParams()
      },
      skip:
        loading ||
        isGraphqlArrayEmptyForNested(data, 'orders') ||
        !isInternal(userData),
      onCompleted: response => {
        if (
          response &&
          response.materialCodesByOrderNumber &&
          response.materialCodesByOrderNumber.data
        ) {
          const resultMap = new Map();
          const regrindCodes = new Set(REGRIND_MATERIAL_CODES);

          // For each order number...
          response.materialCodesByOrderNumber.data.forEach(
            orderNoMaterialCodePair => {
              // Build the list of material codes in this order
              const listOfMaterialCodes = orderNoMaterialCodePair.materialCodes.filter(
                materialCode =>
                  !regrindCodes.has(materialCode.componentItemNumber) &&
                  materialCodeGetsReachLetter(materialCode.componentItemNumber)
              );

              // Set the order number to true or false depending on if it gets REACH letter
              // Map will look like ("1234567": true, "8901234": false, ...etc)
              resultMap.set(
                orderNoMaterialCodePair.orderNumber,
                listOfMaterialCodes.length
              );
            }
          );

          setOrderNumberHasComplianceLetterMap(resultMap);
        }
      }
    }
  );

  const { loading: packingSlipIsLoading } = useQuery(GET_PACKING_SLIPS, {
    variables: { where: getPackingSlipValidatorPayload() },
    skip: loading || isGraphqlArrayEmptyForNested(data, 'orders'),
    onCompleted: response => {
      if (response && response.packingSlips) {
        const packingSlipMap = response.packingSlips.reduce(
          (seed, packingSlip) => {
            const count = seed.get(packingSlip.orderNumber);
            seed.set(packingSlip.orderNumber, count ? count + 1 : 1);
            return seed;
          },
          new Map()
        );
        setOrderNumberToPsCountMap(packingSlipMap);
      }
    }
  });

  const { loading: accountingInvoicesLoading } = useQuery(
    GET_ACCOUNTING_INVOICES,
    {
      variables: { where: getAccountingInvoicePayload() },
      skip: loading || isGraphqlArrayEmptyForNested(data, 'orders'),
      onCompleted: response => {
        if (response && response.accountingInvoices) {
          const accountingInvoices = response.accountingInvoices.reduce(
            (seed, packingSlip) => {
              const count = seed.get(packingSlip.orderNumber);
              seed.set(packingSlip.orderNumber, count ? count + 1 : 1);
              return seed;
            },
            new Map()
          );
          setOrderNumberToAccountingInvoiceCountMap(accountingInvoices);
        }
      }
    }
  );

  const { loading: commercialInvoiceHeadersLoading } = useQuery(
    GET_COMMERCIAL_INVOICES,
    {
      variables: { where: getCommercialInvoicePayload() },
      skip:
        !isInternal(userData) ||
        loading ||
        isGraphqlArrayEmptyForNested(data, 'orders') ||
        !showCommercialInvoices(userData),
      onCompleted: response => {
        if (response && response.commercialInvoices) {
          const { commercialInvoices } = response;
          const CIMap = commercialInvoices.reduce((seed, invoice) => {
            const count = seed.get(invoice.orderNumber);
            seed.set(invoice.orderNumber, count ? count + 1 : 1);
            return seed;
          }, new Map());

          setOrderNumberToCommercialInvoiceMap(CIMap);
        }
      }
    }
  );

  const renderAvailableDocuments = order => {
    return (
      <div className="flex-end">
        <ul className="document-availability__list">
          {!packingSlipIsLoading &&
          orderNumberToPsCountMap.get(_getOrderNumber(order)) ? (
            <li className="document-availability__document-listing">
              {orderNumberToPsCountMap.get(_getOrderNumber(order)) > 1
                ? 'Packing Slips'
                : 'Packing Slip'}
            </li>
          ) : null}
          {!accountingInvoicesLoading &&
          orderNumberToAccountingInvoiceCountMap.get(_getOrderNumber(order)) ? (
            <li className="document-availability__document-listing">
              {orderNumberToAccountingInvoiceCountMap.get(
                _getOrderNumber(order)
              ) > 1
                ? 'Accounting Invoices'
                : 'Accounting Invoice'}
            </li>
          ) : null}
          {!commercialInvoiceHeadersLoading &&
          orderNumberToCommercialInvoiceMap.get(_getOrderNumber(order)) ? (
            <li className="document-availability__document-listing">
              {orderNumberToCommercialInvoiceMap.get(_getOrderNumber(order)) > 1
                ? 'Commercial Invoices'
                : 'Commercial Invoice'}
            </li>
          ) : null}
          {!materialCodesLoading &&
          orderNumberHasComplianceLetterMap.get(_getOrderNumber(order)) ? (
            <li className="document-availability__document-listing">
              Compliance Letter
            </li>
          ) : null}
        </ul>
      </div>
    );
  };

  const renderDocumentAvailibility = order => {
    return (
      <div className="document-availability">
        {loading ||
        packingSlipIsLoading ||
        commercialInvoiceHeadersLoading ||
        accountingInvoicesLoading ||
        materialCodesLoading
          ? 'Determining Document Availability...'
          : 'Documents Available:'}
        {!loading ? renderAvailableDocuments(order) : null}
      </div>
    );
  };

  const _renderShipments = order => {
    return (
      <>
        <div className="units-row align-center">
          <div className="unit-auto tablet-unit-50 phone-unit-100">
            <div className="order-subheader">
              <h3 className="condensed-font margin-right">
                Order Number: {_getOrderNumber(order)}
              </h3>
              <h3 className="condensed-font">
                Purchase Order:{' '}
                {order.purchaseOrderId ? order.purchaseOrderId.value : ''}
              </h3>
            </div>
          </div>
          <div className="unit-auto show-on-phone">
            {renderDocumentAvailibility(order)}
          </div>
          <div className="tablet-unit-50 phone-unit-100 documents-right-column">
            <OxButton
              id={`documents-${_getOrderNumber(order)}`}
              helperClass="document-button"
              giDataAttr="document-button"
              buttonType="a"
              text="Related Documents"
              href={_getDocumentPageRoute(order)}
              withIcon={{
                icon: 'file-text'
              }}
              element="link"
              type="blue"
            />
          </div>
          <div className="unit-auto display-on-tablet-only">
            {renderDocumentAvailibility(order)}
          </div>
        </div>
        <div className="units-row orders__table-container">
          <div className="unit-70">
            <OrderShipments 
              loading={loading} 
              shipmentInformation={[order]}
              accountName={loading ? "" : order.accountName.value}
            />
          </div>
          <div className="unit-auto hide-on-mobile-tablet padding-bottom">
            {renderDocumentAvailibility(order)}
          </div>
        </div>
      </>
    );
  };

  const _emphasizedTitle = order => {
    return loading ? '' : order.accountName && order.accountName.value;
  };

  return _getOrders().map((order, index) => {
    return (
      <OxCard
        key={`${_getOrderNumber(order)}-${Math.random()}`}
        header={{
          title: 'Order For',
          emphasizedTitle: `${_emphasizedTitle(order)}`,
          buttons: [
            () => {
              return (
                <h3
                  className="ox-card__title small"
                  key={`order-date ${order.id}`}
                >
                  {loading
                    ? 'Order Placed - '
                    : `Order Placed - ${order.orderPlacedDate &&
                        order.orderPlacedDate.value}`}
                </h3>
              );
            }
          ]
        }}
      >
        {_renderShipments(order, index)}
      </OxCard>
    );
  });
};

export default ListOfOrders;
