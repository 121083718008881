import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GET_USER_INFO } from '../../api/user/query';
import { handleKeyUpForSearch, isEmailFormatValid } from '../../helpers';
import OxInput from '../tokens/forms/OxInput';

const OxSearch = ({
  searchType,
  searchPlaceholder,
  history,
  name,
  label,
  inputFunction
}) => {
  OxSearch.propTypes = {
    searchType: PropTypes.oneOf(['order', 'account', 'resources']),
    searchPlaceholder: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    inputFunction: PropTypes.func
  };
  const { data: userData } = useQuery(GET_USER_INFO);
  const [userInput, setUserInput] = useState('');
  const { pathname } = useLocation();
  let path = '';
  path = pathname.includes('dashboard') ? 'Dashboard' : 'Not Dashboard';

  // Determine if user has an associated account IDs
  const userHasAccountIds = () => userData?.accountId?.accountIds?.length > 0;

  // Props to send over with plausible events
  const plausibleProps = {
    props: {
      userType: userData.userInfo.userType.name,
      ...(userHasAccountIds()  && {
        accountId: userData.accountId.accountIds[0]
      })
    }
  };

  const _handleSearchClick = () => {
    setUserInput(userInput.replace(/ /g, ''));
    if (searchType === 'account') {
      if (window.plausible) {
        window.plausible('Search for an Account', plausibleProps);
      }
     
      history.push(
        `/accounts/${
          isEmailFormatValid(userInput) ? 'email' : 'details'
        }/${encodeURIComponent(userInput)}`
      );
    } else if (searchType === 'order') {
      if (window.plausible) {
        window.plausible('Search for an Order', plausibleProps);
      }

      history.push(`/my-orders/details/${userInput}`);
    } else if (searchType === 'resources' && !!userInput) {
      if (window.plausible) {
        window.plausible('Searched for a Document', plausibleProps);
      }

      history.push(`/resources/search?query=${userInput}`);
    }
  };

  return (
    <OxInput
      icon="search"
      handleKeyUp={event =>
        handleKeyUpForSearch(
          event,
          history,
          searchType,
          inputFunction
            ? inputFunction(event)
            : setUserInput(event.target.value),
          path,
          plausibleProps
        )
      }
      floatLabel={false}
      name={name}
      placeholder={searchPlaceholder}
      label={label}
      inputType="text"
      handleIconClick={event =>
        inputFunction ? inputFunction(event) : _handleSearchClick()
      }
      className="ox_input__icon--right"
      giDataAttr={`searchInput-${searchType}Details__${userInput}`}
    />
  );
};

export default OxSearch;
